import React from 'react';
import { useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { displayTime, displayShortDate } from "../../utils/dateutils";

type TempHistoryGraphProps = {
    tempHistoryList?: [];
    requiredTemp: number;
    tempRange: string;
  };

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController
);

const options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Reefer Temperature'
        },
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: 'Time'
          }
        },
        y: {
          display: true,
          title: {
            display: true,
            text: 'Temp (F)'
          },
          suggestedMin: -20,
          suggestedMax: 40,
        }
      }
    }

export default function TempHistoryGraph(props: TempHistoryGraphProps) {
    const [tempList, setTempList] = useState([]);
    const [keyList, setKeyList] = useState(['1','2','3']);
    const [maxTempList, setMaxTempList] = useState([])
    const [minTempList, setMinTempList] = useState([])

    if(props.tempHistoryList && tempList.length == 0) {
        var temps = []
        var keys = []
        var min_temps = []
        var max_temps = []
        const stepSize = Math.ceil(props.tempHistoryList.length / 100)
        const temp_range = props.tempRange
        const regex = /([+-]?\d+(\.\d+)?)\s?/
        var matches = temp_range.toString().match(regex)
        
        for (let i = 0; i < props.tempHistoryList.length; i += stepSize) {
            temps.push(props.tempHistoryList[i]['temp']);
            min_temps.push(props.requiredTemp - parseInt(matches[0]))
            max_temps.push(props.requiredTemp + parseInt(matches[1]))
            var date = displayShortDate(new Date(props.tempHistoryList[i]['time_ms'])) + " " + displayTime(new Date(props.tempHistoryList[i]['time_ms']))
            keys.push(date)
        }
        setTempList(temps)
        setKeyList(keys)
        setMaxTempList(max_temps)
        setMinTempList(min_temps)
    }
    
    var data = {
        labels: keyList,
      datasets: [
          {
            label: 'Max Temp',
            fill: false,
            backgroundColor: '#FF0000',
            borderColor: '#FF0000',
            pointStyle: false,
            data: maxTempList,
          }, {
            label: 'Truck Temp',
            fill: false,
            backgroundColor: '#666666',
            borderColor: '#666666',
            data: tempList
        }, {
            label: 'Min Temp',
            backgroundColor: '#0000FF',
            borderColor: '#0000FF',
            pointStyle: false,
            data: minTempList,
            fill: false,
          }
        ]
      };

    return (
        <Chart type='line' data={data} redraw={true} />
    );
}